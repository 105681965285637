import display3dsChallenge from "../3ds/providers/paycentral-3ds-public.js";
import stripeChallengeHandler from "../3ds/providers/stripe/stripe-challenge-display.js";
import * as factory from "../3ds/factory.js";
import { payCentralConfig } from "../3ds/config.js";

/**
 * Has 3DS capability
 * @desc Gateway provider and payment method 3ds payer authentication capability check.
 * @param {any} data
 */
function hasCapability(data) {
    try {
        const isValid = validate(data);
        if (!isValid) return false;

        const method = data.paymentRequestData.PaymentInstrument;
        const currency = data.Currency.toUpperCase();

        // pay central setting: provider enabled.
        const providerName = format(method.MethodData.GatewayProvider);
        if (payCentralConfig.enabledProviders.indexOf(providerName) < 0) return false;

        // provider capability: does provider have 3D Secure & currency code capability.
        const provider = factory.createProvider(data);
        return (provider.has3dsCapability && provider.hasCurrencyCapability(currency));
    } catch (e) {
        console.log("3DS authentication bypassed", "Information: ", e.name + " : " + e.message);
        return false;
    }
};


function validate(data) {
    const method = data.paymentRequestData.PaymentInstrument;
    // graceful exit when required elements are missing.
    if (!method || !method.MethodData || !method.MethodData.Type || !method.MethodData.GatewayProvider || !method.MethodData.Type.includes("CreditCard")) {
        return false;
    } else {
        return true;
    }
};

/**
 * Format gateway name.
 * @desc Standardize gateway name formatting.
 * Note: We don't need this, but it allowed me to ignore the gateway name format (to some degree)
 * @param {String} str
 */
function format(str) {
    return str.replace(" ", "_")
        .replace("(", "")
        .replace(")", "") // necessary
        .toLowerCase();
};

export { hasCapability, factory, display3dsChallenge, stripeChallengeHandler }