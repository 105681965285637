/**
 * Enabled providers
 * @desc Pay Central settings: is 3ds enabled for this provider.
 */
const enabledProviders = ["iats", "imis_pay", "stripe"];

/**
 * Enabled currencies
 * @desc Pay Central settings: is 3ds enabled for this currency code.
 */
const enabledCurrencies = ["EUR", "GBP"];

export const payCentralConfig = {
    enabledProviders,
    enabledCurrencies
};